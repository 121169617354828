import React from 'react';
import Helmet from 'react-helmet';

import {
    Default
} from '../../components';

import {
    Container,
    Heading,
    SubHeading,
    Body
} from '../../components/PageStyles/UsesStyles';

const Uses = ({ location }) => {
    return (
        <Default location={location}>
            <Helmet>
                <title>Kebijakan Privasi</title>
                <meta name="description" content="Kebijakan Privasi dan Kepatuhan GDPR"/>
            </Helmet>
            <Container>
                <Heading>
                Kebijakan Privasi
                </Heading>
                <Body>
                Alamat situs web kami adalah: https://xperria.com
                </Body>

                <SubHeading>
                  Komentar
                </SubHeading>
                <Body>  
                Ketika pengunjung meninggalkan komentar pada situs tersebut, kami mengumpulkan data yang ditampilkan dalam kolom komentar, dan juga alamat IP pengunjung dan string agen pengguna browser untuk membantu mendeteksi spam.
                <br></br>
                Sebuah string anonym dibuat dari alamat email Anda (juga disebut sebagai hash) dapat diberikan ke layanan Gravatar untuk melihat apakah Anda sedang menggunakannya. Layanan kebijakan privasi Gravatar tersedia disini: https://automattic.com/privacy/. Setelah persetujuan atas komentar Anda, foto profil Anda dapat dilihat oleh public dalam konteks komentar Anda.  
                <SubHeading>
                Formulir kontak
                </SubHeading>
                Informasi yang dikirimkan melalui formulir kontak pada situs kami dikirimkan ke email perusahaan kami, yang di hosting oleh Gmail. Google mematuhi kebijakan EU “Perisai Privasi” dan Anda dapat menemukan informasi lebih lanjut mengenai hal tersebut disini.
                <br></br>
                Meskipun kami menyimpan kiriman ini untuk tujuan layanan pelanggan, mereka tidak pernah digunakan untuk tujuan pemasaran atau dibagikan kepada pihak ketiga.
                <SubHeading>
                Cookies
                </SubHeading>
                Ketika Anda mengunjungi Situs tersebut, server kami secara otomatis mencatat informasi non-pribadi berdasarkan penggunaan Anda pada situs tersebut. Informasi ini mungkin termasuk, namun tidak terbatas pada, alamat IP komputer Anda, tipe browser. Kami menggunakan “cookies” untuk mengumpulkan informasi untuk menyediakan dan meningkatkan pelayanan, fitur dan konten dari situs tersebut. Juga, Situs dapat mengumpulkan informasi melalui situs Mitra.
                <br></br>
                Situs kami menggunakan cookies sebagai berikut:
                <br></br>
                - Google Analytics cookies: ini diatur untuk memonitor dan melacak perilaku pengunjung pada situs tersebut.
                <br></br>
                - Pro cookies: ini diatur untuk keperluan anti spam, jadi orang-orang yang berkomentar ulasan dan artikel tidak dapat menyalahgunakan layanannya.
                <SubHeading>
                Konten yang disematkan pada situs lainnya
                </SubHeading>
                Artikel pada situs ini dapat menyertakan konten yang disematkan (contohnya video, gambar, artikel, dll). Konten yang disematkan dari situs lain berperilaku dengan cara yang persis sama seolah-olah pengunjung telah mengunjungi situs web lain.
                <br></br>
                Situs web ini dapat mengumpulkan data tentang Anda, menggunakan cookie, menyematkan pelacakan pihak ketiga tambahan, dan memantau interaksi Anda dengan konten yang disematkan tersebut, termasuk melacak interaksi Anda dengan konten yang disematkan jika Anda memiliki akun dan masuk ke situs web itu.
                </Body>
            </Container>
        </Default>
    );
}

export default Uses;
